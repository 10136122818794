
export const clientName = "Port of Luanda";
export const documentationUrl = "https://d2l2yquylnjqza.cloudfront.net"; // Production documentation
export const mapConfig = {
    defaultLocation: {
        lat: 8.92833333,
        lng: -13.27361111,
        zoom: 13,
    },
    wms: [
        {
            url: "https://wms.api.total-demo.total-demo.eu.sercelconcept.com/wms",
            marlinAuth: true,
            layers: [
                {
                    id: "/zones-fixed",
                },
            ],
        },
    ],
};

export const ActionTypeIds = {
    MOVEMENT: "0a803439-330c-4346-a174-f11f73c8b3e9",
    EVENT: "ee3e3479-13ef-410e-a2f3-6ffed837c9f7",
};

export const ContactTypeIds = {
    AGENT: "422867f7-e7f0-4716-bc47-0ef6c6b4e73b",
    STEVEDORE: "440e2433-6315-460b-a6bd-8430d88ef6d9",
    PORT_STAFF: "55c883ab-cbf0-425d-a0d6-ca9fba71eb64",
    VESSEL_AGENT: "e923d898-04d2-47ca-a63b-b71452b3406c",
    VESSEL_MASTER: "70b0d0c6-f4ec-4a18-81a6-c589bd5ddcbf",
};

export const TodoType = {
    EMAIL_DEFRA: "EMAIL_DEFRA",
    EMAIL_CERS: "EMAIL_CERS",
    EMAIL_POLICE: "EMAIL_POLICE",
    EMAIL_FIRE_BRIGADE: "EMAIL_FIRE_BRIGADE",
    PAPERWORK_RECEIVED: "PAPERWORK_RECEIVED",
    DANGEROUS_CARGO: "DANGEROUS_CARGO",
    DIVERS_REQUIRED: "DIVERS_REQUIRED",
};

export const arrivalChecklistTypes = [
    TodoType.PAPERWORK_RECEIVED,
    TodoType.EMAIL_CERS,
];

export const departureChecklistTypes = [
    TodoType.PAPERWORK_RECEIVED,
    TodoType.EMAIL_CERS,
];

export const tidesEnabled = true;

export const weatherEnabled = true;

export const dashboardConfig = {
    enabled: false,
    tableauUrl: "",
};

export const AISStatusConfig = {
    smartportS3: "ais/status.json",
    updateInterval: 15,
};
