/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://ujssi2miizfwnce6hktv2gzwha.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-1:ae9fdb0e-dbbc-4627-8139-d550d7f3b388",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_tqUIiCjQS",
    "aws_user_pools_web_client_id": "68r3b63mb0megaj3gjfva363nr",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "smartport1fd30e95c68846669d7fec4ba60d518e153621-total",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
